import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { config } from "../config";
import CatalogCard from "../components/cards/catalogCard.tsx";
import LoadingSVG from "../components/assets/svg/loading";
import { PaginationButton } from "../components/buttons/paginationButton.tsx";
import ErrorDisplay from "../components/errorDisplay.tsx";
import errorMessageFromCode from "../components/utilities/errorMessageFromCode";
import { LeftArrow, RightArrow } from "../components/assets/svg/LRarrows";

interface CatalogItem {
  id: string;
  name: string;
  description: string;
  images: string[];
}

interface CatalogResponse {
  items: CatalogItem[];
  currentPage: number;
  maxPage: number;
}

const Catalog = () => {
  const [location] = useSearchParams();
  const [data, setData] = useState<CatalogResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchCatalog = () => {
    setError(null);
    setIsError(false);
    fetch(`${config.addresses.api}/catalog?page=${location.get("page")}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setIsError(true);
          setError(errorMessageFromCode(response.status));
        }
      })
      .then((json: CatalogResponse) => {
        setData(json);
      })
      .catch((error: any) => {
        setIsError(true);
        const errorMessage = error.message
          ? error.message
          : "Service not available";
        setError(errorMessageFromCode(errorMessage));
      });
  };

  useEffect(() => {
    setIsError(false);
    setError(null);
    fetchCatalog();
    if (
      !location.has("page") ||
      Number(location.get("page")) < 1 ||
      (data && Number(location.get("page")) > data.maxPage)
    ) {
      location.set("page", "1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {data && !isError ? (
        <>
          <div className="flex flex-row flex-wrap justify-evenly">
            {data.items.map((item) => (
              <CatalogCard
                className="relative"
                img={item.images}
                title={item.name}
                description={item.description}
                key={item.id}
                link={`/catalog/${item.id}`}
              />
            ))}
          </div>
          <div>
            {data && data.items.length > 0 ? (
              <div className="flex flex-row justify-evenly">
                {Number(location.get("page")) > 1 ? (
                  <PaginationButton text={<LeftArrow width="32" />} page={-1} />
                ) : (
                  <PaginationButton
                    text={<LeftArrow width="32" />}
                    className="opacity-20"
                    onClick={() => {}}
                    page={-1}
                  />
                )}
                {Number(location.get("page")) < data.maxPage ? (
                  <PaginationButton text={<RightArrow width="32" />} page={1} />
                ) : (
                  <PaginationButton
                    text={<RightArrow width="32" />}
                    className="opacity-20"
                    onClick={() => {}}
                    page={1}
                  />
                )}
              </div>
            ) : (
              <>
                <div className="text-center mb-10">No more items to show</div>
                <PaginationButton
                  text="Previous"
                  page={-(data.currentPage - data.maxPage)}
                />
              </>
            )}
          </div>
        </>
      ) : !data && !isError ? (
        <LoadingSVG />
      ) : (
        isError && <ErrorDisplay error={error} retry={fetchCatalog} />
      )}
    </>
  );
};

export default Catalog;
