export default function errorMessageFromCode(errorCode, text = "defaultText") {
  switch (errorCode) {
    case 401:
      return "Please verify your email";
    case 404:
      return "The address is unreachable";
    case 500:
      return "An error has occured, please try again later";
    default:
      if (text !== "defaultText") {
        return text;
      } else {
        return "An error has occured, please try again later";
      }
  }
}
