import React, { useEffect, useState } from "react";
import { config } from "../config.jsx";
import LinkButton from "./buttons/LinkButton.tsx";
import DiscordSVG from "./assets/svg/discord";

const HomePresentation = () => {
  const [buttonLink, setButtonLink] = useState("/login");
  const customToken = localStorage.getItem("customToken");
  useEffect(() => {
    if (customToken) {
      setButtonLink("/upload");
    }
  }, [customToken]);

  return (
    <div className="my-10 sm:my-0">
      <div className="flex flex-col justify-center items-center">
        <img
          src={`${config.addresses.assets}/png/logo.png`}
          className="object-fill opacity-30"
          alt=""
        />
        <div className="absolute justify-center items-center font-bold text-5xl">
          <p>3D printing on demand</p>
          <div className="flex flex-row flex-wrap justify-center mt-5">
            <p>to make your&nbsp;</p>
            <span className="text-Cprimary dark:text-CprimaryDark">
              ideas&nbsp;
            </span>
            <p>come&nbsp;</p>
            <span className="mb-10 text-Caccent dark:text-CaccentDark">
              true
            </span>
          </div>
          <div className="mb-10">
            <LinkButton text="Catalog" link="/catalog" />
            <LinkButton text="Upload" link={buttonLink} />
          </div>
          <div className="flex justify-center w-full">
            <div className="transition md:hover:scale-110 md:hover:-translate-y-1 w-max">
              <a
                href="https://discord.gg/XCdqggZp7M"
                className="flex flex-row text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-3xl w-full sm:w-auto pl-3 pr-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <div className="flex h-10 w-10">
                  <DiscordSVG />
                </div>
                Join us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePresentation;
