import React, { useEffect, useState } from "react";
import { config } from "../config.jsx";
import SubmitButton from "../components/buttons/submitButton.tsx";
import LoadingSVG from "../components/assets/svg/loading.js";
import ErrorDisplay from "../components/errorDisplay.tsx";
import setErrorOnCode from "../components/utilities/errorMessageFromCode.js";

export interface apiData {
  user: User;
  orders: Orders;
}

interface Orders {
  id: number; //order id
  productid: number; //ordered product id
  status: "TO-DO" | "PROCESSING" | "DONE";
  paid: "YES" | "NO";
  quantity: number;
  material: "PLA" | "ABS" | "PETG" | "TPU";
  color: "WHITE" | "BLACK" | "BLUE" | "RED";
  price: number;
  currencycode: string;
  productName: string;
  productImages: string[];
}

interface User {
  email: string;
  username: string;
  profilePicture: string;
  firstname: string;
  lastname: string;
  phonenumber: string;
  promocode: string;
  grade: "Iron" | "Bronze" | "Silver" | "Gold";
}

const Profile = () => {
  const [apiData, setApiData] = useState<apiData>();
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  const fetchProfile = () => {
    fetch(`${config.addresses.api}/profile`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("customToken")}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setIsError(true);
          setError(setErrorOnCode(response.status));
        }
      })
      .then((json) => setApiData(json))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div className="h-full w-full flex flex-col sm:flex-row justify-evenly items-start">
      {apiData && !isError ? (
        <>
          <div className="flex flex-col justify-center items-center w-full sm:w-1/3 h-full sm:outline rounded-lg outline-CgoldenColor p-5 sm:m-5">
            <div className="flex flex-col justify-center items-center w-full gap-5 p-5 rounded-lg overflow-hidden">
              <img
                src={
                  apiData.user.profilePicture ??
                  "https://cdn.goldenlab.dev/assets/png/logo.png"
                }
                alt="Profile"
                className="rounded-full h-40 w-40 overflow-hidden outline"
              />
              <div className="flex flex-col justify-evenly w-full font-bold text-2xl gap-5">
                <p>{apiData.user.username}</p>
                <p>{apiData.user.email}</p>
              </div>
            </div>
            <SubmitButton
              text="Forgot Password"
              onClick={() => (window.location.href = "/forgot-password")}
              className="mt-5"
            />
          </div>
          <div className="w-full p-10 sm:p-0 sm:w-1/3">
            <div className="p-2 my-5 outline outline-CtextDark rounded-full">
              <h2>
                Referral Code:{" "}
                {apiData.user.promocode ? (
                  <span className="text-Caccent">{apiData.user.promocode}</span>
                ) : (
                  <span className="text-Csecondary">No referral code...</span>
                )}
              </h2>
              <h2>
                Grade:{" "}
                {apiData.user.grade ? (
                  <span className="text-Csecondary">{apiData.user.grade}</span>
                ) : (
                  <span className="text-Csecondary">No grade...</span>
                )}
              </h2>
            </div>
            <div className="rounded-lg sm:overflow-y-scroll no-scrollbar">
              <h1 className="font-bold text-2xl">My Orders</h1>
              <div>
                {Array.isArray(apiData.orders) && apiData.orders.length > 0 ? (
                  apiData.orders.map((order, index) => (
                    <div
                      key={index}
                      className="flex flex-col flex-nowrap p-1 m-4 outline outline-CtextDark rounded-lg outline-2 text-ellipsis font-bold gap-1"
                    >
                      <p>
                        Order Number:{" "}
                        <span className="text-Caccent">{order.id}</span>
                      </p>
                      <div className="flex flex-row justify-evenly w-full truncate">
                        <img
                          src={order.productImages[0]}
                          alt="orderImage"
                          className="h-24 w-24 object-cover rounded-xl"
                        />
                        <div className="mx-2">
                          <p className="flex flex-row flex-nowrap truncate">
                            <span className="text-Csecondary">
                              <a
                                href={`https://goldenlab.dev/catalog/${order.productid}`}
                                aria-label={`View product ${order.productName}`}
                              >
                                {order.productName}
                              </a>
                            </span>
                          </p>
                          <p>
                            Quantity:{" "}
                            <span className="text-Csecondary">
                              {order.quantity}
                            </span>
                          </p>
                          <p>
                            Status:{" "}
                            <span className="text-Caccent">{order.status}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="text-Caccent">No Orders...</span>
                )}
              </div>
            </div>
          </div>
        </>
      ) : !apiData && !isError ? (
        <LoadingSVG />
      ) : (
        isError && <ErrorDisplay error={error} retry={fetchProfile()} />
      )}
    </div>
  );
};

export default Profile;
