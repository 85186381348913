import React from "react";
import SubmitButton from "./buttons/submitButton.tsx";
import SecondaryButton from "./buttons/SecondaryButton.tsx";

const errorDisplay = (props: any) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="pb-10">{props.error}</div>
      <SubmitButton onClick={props.retry} text="Reload" />
      <SecondaryButton
        onClick={() => window.location.assign("/")}
        text={"Back to Home"}
      />
    </div>
  );
};

export default errorDisplay;
