import React, { useState } from "react";
import { config } from "../../config";
import SubmitButton from "../buttons/submitButton.tsx";
import errorMessageFromCode from "../utilities/errorMessageFromCode";

const EmailPasswordReset = () => {
  const [email, setEmail] = useState("");

  const handleSumbit = (e) => {
    e.preventDefault();
    fetch(`${config.addresses.api}/forgot`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => {
        if (response.ok) {
          return (
            alert("A reset link has been sent to your email."),
            (window.location.href = "/")
          );
        } else {
          response.status
            ? errorMessageFromCode(response.status)
            : errorMessageFromCode(
                null,
                "An error has occured, please try again later"
              );
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((error) => {
        console.error(error);
        alert("An error occurred while sending the reset link.");
      });
  };
  return (
    <div className="flex justify-center w-full">
      <form
        className="flex flex-col items-center w-1/3 gap-5"
        onSubmit={handleSumbit}
      >
        <label htmlFor="email" className="block text-sm font-bold">
          Email
        </label>
        <input
          type="email"
          id="email"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <SubmitButton text="Send Reset Link" />
      </form>
    </div>
  );
};

export default EmailPasswordReset;
