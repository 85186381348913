import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../config";
// import Searchbar from "./searchbar";
// import SubmitButton from "../components/buttons/submitButton";

function NavBar() {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("customToken");
    setLoggedIn(!!token);
  }, []);

  const handleSignOut = () => {
    localStorage.removeItem("customToken");
    setLoggedIn(false);
    navigate("/");
  };

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row justify-center">
        <a href="/" className="flex flex-row mx-5">
          {" "}
          <img
            src={`${config.addresses.assets}/png/logo.png`}
            alt="Goldenlab logo"
            className="w-10 h-10"
          />
          <span className="flex flex-col justify-center p-0 ml-3 text-xl font-semibold">
            Goldenlab
          </span>
        </a>
      </div>
      {/* {window.location.href.includes("catalog") && <Searchbar />} */}
      {/* {window.location.href.includes("catalog") && (
        <SubmitButton
          onClick={toggleViewMode}
          className='mb-4 p-2 bg-blue-500 text-white rounded'
          text={`Switch to ${
            viewMode === "images" ? "3D Viewer" : "Images View"
          }`}
        />
      )} */}
      {loggedIn ? (
        <div className="flex flex-row">
          <div className="flex flex-row">
            <a href="/profile" className="block p-4 hover:underline">
              {" "}
              My Profile
            </a>
            {/* <a href='/calendrier' className='block p-4 hover:underline'>
              {" "}
              My Orders
            </a> */}
            <button
              onClick={handleSignOut}
              className="block p-4 hover:underline cursor-pointer"
            >
              Sign Out
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-row">
          <a href="/login" className="block p-4 hover:underline">
            {" "}
            Sign In
          </a>
          <a href="/register" className="block p-4 hover:underline">
            {" "}
            Register
          </a>
        </div>
      )}
    </div>
  );
}

export default NavBar;
