import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { config } from "../config.jsx";
import SubmitButton from "../components/buttons/submitButton.tsx";
import UploadSVG from "../components/assets/svg/upload.js";
// import DeleteSVG from "../components/assets/svg/delete";

export default function UploadFile() {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [files, setFiles] = useState<string[]>([]);

  useEffect(() => {
    !localStorage.getItem("customToken") && window.location.replace("/login");
  }, []);

  const onDrop = useCallback((files) => {
    setFiles((prevFiles) => [...prevFiles, ...files]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png", ".jpg", ".jpeg", ".gif"],
      "model/stl": [".stl"],
    },
    onDrop,
  });

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    for (let file in files) {
      formData.append("files", files[file]);
    }
    axios({
      url: `${config.addresses.api}/upload`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("customToken")}`,
      },
      data: formData,
      onUploadProgress: (e) => {
        const progress = Math.round((e.loaded * 100) / (e.total ?? 1));
        setProgress(progress);
      },
    })
      .then((response) => {
        console.log(response);
        alert("Upload Successful");
        window.location.replace("/");
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          alert("You are not signed in");
          window.location.href = "/login";
        }
        console.error(error);
      });
  }

  const itemDisplay = (file) => {
    return (
      <div className="flex flex-row flex-wrap text-ellipsis w-auto p-1 z-20 text-gray-500 dark:text-gray-400">
        {file.map((file, index) => (
          <div key={index}>
            <button
              className="ease-in-out flex flex-row justify-center items-center hover:line-through px-1"
              onClick={() => {
                let newFiles = files.filter((item) => item !== file);
                setFiles(newFiles);
              }}
            >
              {file.name}
            </button>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col sm:flex-row justify-evenly items-center transition-all">
      <div className="flex-col h-full w-full sm:w-1/2 m-5 sm:m-0 p-10 sm:p-0">
        <div>
          <div
            {...getRootProps()}
            className="flex flex-col items-center justify-center h-64 transition-all border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <UploadSVG />
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Drop your files HERE</span>
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <UploadSVG />
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  PNG, JPG, GIF and STL (MAX. 2GB)
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  duplicates will be ignored
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row flex-nowrap">
          {files.length > 0 && itemDisplay(files)}
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col justify-evenly items-center w-full sm:w-auto h-full gap-7 p-6 sm:p-0"
      >
        <h1 className="rounded-lg outline p-10 font-bold text-lg">
          Post your own file
        </h1>
        <input
          type="text"
          placeholder="Name of your post"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Description"
          id="description"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          onChange={(e) => setDescription(e.target.value)}
        />
        {!document.getElementById("description") && (
          <h1>Leave empty for no description</h1>
        )}
        <SubmitButton text="Upload" />

        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div
            className="transition-all bg-blue-600 h-2.5 rounded-full"
            style={{ width: progress + "%" }}
          >
            {/* {progress > 0 && progress + "%"} */}
          </div>
        </div>
      </form>
    </div>
  );
}
