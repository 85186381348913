import React from "react";

const SecondaryButton = (props: any) => {
  const { className, ...buttonProps } = props;
  const defaultClassName: string = "";
  return (
    <button
      type="submit"
      className={[defaultClassName, className].join(" ")}
      {...buttonProps}
    >
      {props.text}
    </button>
  );
};

export default SecondaryButton;
