import React, { useCallback, useEffect, useState } from "react";
import { config } from "../config.jsx";
import { LeftArrow, RightArrow } from "../components/assets/svg/LRarrows.js";
import { StlViewer } from "react-stl-viewer";
import LoadingSVG from "../components/assets/svg/loading.js";
import SetErrorOnCode from "../components/utilities/errorMessageFromCode.js";
import ErrorDisplay from "../components/errorDisplay.tsx";
import SecondaryButton from "../components/buttons/SecondaryButton.tsx";

// interface OrderInterface {
//   email: string;
//   id: number;
//   material: string;
//   color: string;
//   quantity: Number;
// }

interface baseData {
  id: number;
  name: string;
}

interface imageData {
  description: string;
  images: string[];
}

interface stlData {
  stlFiles: string[];
}

interface apiData extends baseData, imageData, stlData {}

const Item = () => {
  const [isError, setIsError] = useState<boolean | null>(false);
  const [error, setError] = useState<string | null>(null);
  // const [order, setOrder] = useState<OrderInterface | null>(null); TODO after quantity slider & material selection & color selection
  const [email, setEmail] = useState<string>("");
  const [imageId, setImageId] = useState<number>(0);
  const [StlId, setStlId] = useState<number>(0);
  const [viewMode, setViewMode] = useState<string>("images");
  const [data, setData] = useState<apiData | null>(null);
  const id = parseInt(window.location.pathname.split("/")[2]);

  const fetchImages = useCallback(async () => {
    setData(null);
    setIsError(false);
    setError(null);
    await fetch(`${config.addresses.api}/catalog?id=${id}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setIsError(true);
          setError(SetErrorOnCode(response.status));
        }
      })
      .then((json) => setData(json))
      .catch((error) => {
        setIsError(true);
        setError(SetErrorOnCode(error));
      });
  }, [id]);

  const fetchStl = useCallback(async () => {
    setData(null);
    setIsError(false);
    setError(null);
    await fetch(`${config.addresses.api}/stl?id=${id}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setIsError(true);
          setError(SetErrorOnCode(response.status));
        }
      })
      .then((json) => setData(json))
      .catch((error) => {
        setIsError(true);
        setError(SetErrorOnCode(error));
      });
  }, [id]);

  const postPreOrder = async () => {
    if (!email) {
      alert("No email entered");
      return;
    }
    try {
      const response = await fetch(`${config.addresses.api}/preorder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          id: id,
          material: "PLA",
          color: "WHITE",
          quantity: 1,
        }),
      });
      if (response.status === 400) {
        alert("Verify email");
      } else if (!response.ok) {
        setIsError(true);
        setError(SetErrorOnCode(response.status));
      } else {
        alert("Pre-order successful");
        window.location.replace("/");
      }
    } catch (error) {
      setIsError(true);
      setError(SetErrorOnCode(error));
    }
  };

  useEffect(() => {
    setData(null);
    setIsError(false);
    setError(null);
    if (viewMode === "images") {
      fetchImages();
    } else if (viewMode === "3D") {
      fetchStl();
    }
  }, [viewMode, fetchImages, fetchStl]);

  return (
    <>
      {data && !isError ? (
        <div className="flex flex-col sm:flex-row justify-center p-1">
          <div className="flex flex-col justify-center items-center sm:w-1/2 mr-20">
            <div className="flex flex-row justify-evenly items-center">
              {data.images
                ? data.images.length > 1 && (
                    <button
                      className="transition-all hover:opacity-50 p-2 m-2 rounded-full shadow h-20 w-20 focus:outline-none focus:shadow-outline"
                      onClick={() =>
                        setImageId(
                          (imageId - 1 + data.images.length) %
                            data.images.length
                        )
                      }
                    >
                      <LeftArrow />
                    </button>
                  )
                : data.stlFiles &&
                  data.stlFiles.length > 1 && (
                    <button
                      className="transition-all hover:opacity-50 p-2 m-2 rounded-full shadow h-20 w-20 focus:outline-none focus:shadow-outline"
                      onClick={() =>
                        setStlId(
                          (StlId - 1 + data.stlFiles.length) %
                            data.stlFiles.length
                        )
                      }
                    >
                      <LeftArrow />
                    </button>
                  )}
              {viewMode === "images" &&
              data.images &&
              data.images.length > 0 ? (
                <div className="w-fit relative m-4">
                  <img
                    className="flex rounded-lg overflow-hidden object-contain relative"
                    src={
                      data.images[imageId] ??
                      config.addresses.assets + "/png/noimg.png"
                    }
                    alt=""
                  />
                  <button
                    className="transition-all w-max top-0 right-0 p-5 m-2 outline outline-CbackgroundDark dark:outline-Cbackground rounded-md overflow-hidden absolute max-sm:hidden"
                    onClick={() => setViewMode("3D")}
                  >
                    3D
                  </button>
                </div>
              ) : data.stlFiles && data.stlFiles.length > 0 ? (
                <div className="w-fit relative m-4">
                  <div className="flex justify-center items-center h-96 w-96 rounded-lg overflow-hidden outline outline-1 outline-black dark:outline-white hover:cursor-grab active:cursor-grabbing">
                    <StlViewer
                      style={{ width: "100%", height: "100%" }}
                      key={data.stlFiles[StlId]}
                      url={data.stlFiles[StlId]}
                      orbitControls={true}
                    />
                  </div>
                  <button
                    className="transition-all w-max top-0 right-0 p-5 m-2 outline outline-CbackgroundDark dark:outline-Cbackground rounded-md overflow-hidden absolute hover:brightness-200 max-sm:hidden"
                    onClick={() => setViewMode("images")}
                  >
                    2D
                  </button>
                </div>
              ) : (
                <img
                  className="transition-all delay-150 flex h-96 w-96 rounded-lg overflow-hidden object-cover m-2"
                  src={config.addresses.assets + "/png/noimg.png"}
                  alt=""
                />
              )}
              {data.images
                ? data.images.length > 1 && (
                    <button
                      className="transition-all hover:opacity-50 p-2 m-2 rounded-full shadow h-20 w-20 focus:outline-none focus:shadow-outline"
                      onClick={() =>
                        setImageId((imageId + 1) % data.images.length)
                      }
                    >
                      <RightArrow />
                    </button>
                  )
                : data.stlFiles &&
                  data.stlFiles.length > 1 && (
                    <button
                      className="transition-all hover:opacity-50 p-2 m-2 rounded-full shadow h-20 w-20 focus:outline-none focus:shadow-outline"
                      onClick={() =>
                        setStlId((StlId + 1) % data.stlFiles.length)
                      }
                    >
                      <RightArrow />
                    </button>
                  )}
            </div>
            <div className="flex flex-row flex-nowrap justify-center overflow-scroll scroll-smooth no-scrollbar w-full">
              {data.images
                ? data.images.map((img, index) => (
                    <img
                      key={index}
                      className="transition-all ease-in-out h-20 w-auto rounded-lg hover:opacity-50 hover:cursor-pointer object-cover m-2"
                      src={img}
                      alt=""
                      onClick={() => setImageId(index)}
                    />
                  ))
                : data.stlFiles &&
                  data.stlFiles.map((stl, index) => (
                    <button
                      onClick={() => setStlId(index)}
                      className="rounded-lg overflow-hidden m-2 h-20 w-20 transition-all outline outline-1 outline-black dark:outline-white hover:opacity-50 hover:cursor-pointer"
                    >
                      <StlViewer
                        style={{ width: "100%", height: "100%" }}
                        url={stl}
                        key={index}
                        orbitControls={false}
                      />
                    </button>
                  ))}
            </div>
          </div>
          <div className="flex flex-col justify-evenly items-center w-full sm:w-1/3">
            <div>
              <h1 className="title-font text-xl font-bold text-Ctext dark:text-CtextDark mb-3">
                {data.name ?? "No title"}
              </h1>
              <p className="title-font text-lg font-light text-Ctext dark:text-CtextDark mb-3">
                {data.description ?? "No description"}
              </p>
            </div>
            <input
              className="w-2/3 sm:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              type="email"
              value={email}
              pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
              onKeyDown={(e) => e.key === "Enter" && postPreOrder()}
            />
            <div className="flex flex-col">
              <button
                className="m-1 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-content px-5 py-2.5 my-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={postPreOrder}
              >
                Pre-Order
              </button>
              <SecondaryButton
                text="Back to catalog"
                className="m-1"
                onClick={() => window.location.assign("/catalog")}
              />
            </div>
          </div>
        </div>
      ) : !data && !isError ? (
        <LoadingSVG />
      ) : isError ? (
        <ErrorDisplay
          error={error}
          retry={viewMode === "images" ? fetchImages : fetchStl}
        />
      ) : null}
    </>
  );
};

export default Item;
