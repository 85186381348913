import React from "react";
import { CardBody, CardContainer, CardItem } from "../ui/3d-card.tsx";
import { config } from "../../config.jsx";

const CatalogCard = (props: any) => {
  return (
    <CardContainer className="mx-1">
      <CardBody className="relative flex flex-col justify-between w-[20rem] sm:w-[20rem] h-auto rounded-xl p-5 border bg-gray-50 group/card dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1]">
        <CardItem translateZ="60" className="w-full">
          <a href={props.link} title="Card">
            <img
              src={props.img[0] ?? config.addresses.assets + "/png/noimg.png"}
              height="1000"
              width="1000"
              className="h-max w-full object-cover rounded-xl group-hover/card:shadow-xl"
              alt="thumbnail"
            />
          </a>
        </CardItem>{" "}
        <CardItem
          translateZ="30"
          className="flex flex-col w-full overflow-hidden gap-1 p-3"
        >
          <div className="text-xl font-bold text-neutral-600 dark:text-white truncate">
            {props.title}
          </div>
          <div className="text-neutral-500 text-sm w-full mt-2 dark:text-neutral-300 truncate">
            {props.description}
          </div>
        </CardItem>
        <div className="flex justify-between items-center mt-2">
          <CardItem
            translateZ={20}
            as="button"
            className="px-4 py-2 rounded-xl text-xs font-normal dark:text-white"
          >
            <div className="flex">
              <p className="mr-3 inline-flex items-center ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200 text-gray-400">
                <svg
                  className="w-4 h-4 mr-1"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
                {props.likeCounter}
              </p>
              <p className="inline-flex items-center leading-none text-sm text-gray-400">
                <svg
                  className="w-4 h-4 mr-1"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                </svg>
                {props.commentCounter}
              </p>
            </div>
          </CardItem>
          <CardItem
            translateZ={20}
            as="button"
            className="px-4 py-2 rounded-xl  bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 text-xs font-bold"
          >
            <a
              href={props.link}
              className="flex flex-row justify-center items-center inset-y-0 p-2 hover:underline text-Cbackground"
            >
              I want this
              <svg
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </CardItem>
        </div>
      </CardBody>
    </CardContainer>
  );
};

export default CatalogCard;
